// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iuWTOiVShqt9LN8aP_h5{display:flex;column-gap:24px;position:absolute;bottom:32px;left:32px}.iuWTOiVShqt9LN8aP_h5>img{cursor:pointer}.iuWTOiVShqt9LN8aP_h5>p,.iuWTOiVShqt9LN8aP_h5>a{margin-top:8px;font-size:16px !important;max-width:80vw;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.iuWTOiVShqt9LN8aP_h5>a{filter:brightness(90%)}.iuWTOiVShqt9LN8aP_h5>a:hover{filter:brightness(100%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"geo": `iuWTOiVShqt9LN8aP_h5`
};
export default ___CSS_LOADER_EXPORT___;
